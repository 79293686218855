import React from "react";

function Navbar() {
    return (
      <div className="bg">
          <div className="logo">KRISHNA RAI</div>
              <div className="navbar">
          <a className="active nav-link" href="#home">Home</a>
          <a className="nav-link" href="#about">About</a>
          <a className="nav-link" href="#service">Service</a>
          <a className="nav-link" href="#contact">Contact</a>
        </div>

        <div className="mobile-navbar">
        <div className="logo">KRISHNA RAI</div>
      <input type="checkbox" id="menu-toggle" className="menu-toggle" />
      <label htmlFor="menu-toggle" className="menu-icon">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </label>
      <ul className="nav-links">
        <li className="nav-link"><a href="#home">Home</a></li>
        <li className="nav-link"><a href="#about">About</a></li>
        <li className="nav-link"><a href="#service">Service</a></li>
        <li className="nav-link"><a href="#contact">Contact</a></li>
      </ul>
        </div>
      </div>
      


    );
  }
  export default Navbar;
  