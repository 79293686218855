import React from "react";

function Home() {
  return (
    <div>
      <section id="home">
        <div className="home_image">
          <img src="../../images/home.png" />
        </div>
        <div className="header">
          {/* <span className="up ">Hi!<br/>I am Krishna Rai
                <br />Marketing Managar
                </span> */}
          <button className="buttom">Visit My Works</button>
        </div>
        <div className="mobile_image">
        <img src="../../images/mobile_home.png" />
        </div>
      </section>
    </div>
  );
}
export default Home;
