/* eslint-disable jsx-a11y/alt-text */
import React from "react";
function About() {
  return (
    <div>
      <section id="about">
        <div className="name">
          I'm Krishna, a Creative Marketer
          <a href="mailto:{{ $footer->email }}">
            <p>krishnarai0759@gmail.com</p></a>
            <a href="tel:{{ $footer->phoneno }}">
            <p>+977 985-2078275</p></a>
        <button className="work">See my work</button>
        </div>
        <div className="row photo">
          <div className="col-md-6 pic">
            <img src="../../images/meet.jpeg" />
          </div>
          <div className="col-md-6 text">
            <p>Meet Krishna</p>
            <h3>
            Krishna is a versatile marketing professional experienced in each
            stage of the marketing and sales process. He is a thorough
            researcher skilled in identifying target markets and customer
            insights to inform effective marketing programs. Krishna uses an
            adept eye for design and powerful writing and public speaking skills
            to create attractive and easily understandable communications for
            the target markets in any given project.
            </h3>
          </div>
        </div>
      </section>
    </div>
  );
}
export default About;
