/* eslint-disable jsx-a11y/alt-text */
import React from "react";

function Contact() {
  return (
    <section id="contact">
      <div>
        <div className="container">
          <div className="row easy">
            <div className="find">
             I'm easy to find. 
           <p>DON'T BE SHY!</p> 
           </div>
          </div>
          <div className="row contact">
            <div className="col-md-6 office">
              <img src="../../images/contact.jpeg" />
            </div>
           <div className="col-md-6 txt ">
           <div className="form">
                <p>Name*</p>
                <input
                  type="text"
                  size="50"
                  class="form-control"
                  placeholder="Your Name"
                  required=""
                />
                <p>Email*</p>
                <input
                  type="text"
                  size="50"
                  class="form-control"
                  placeholder="Your Email"
                  required=""
                />
                <p>Message*</p>
                <textarea
                  name="comment"
                  id="comment"
                  rows="4"
                  class="form-control"
                  placeholder="Write Something"
                  style={{ marginTop: "10px", display: "block" }}
                ></textarea>
              </div>
               <button className="submit">Submit</button>
           </div>
            </div>
          </div>
        </div>
    </section>
  );
}
export default Contact;
