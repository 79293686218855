
import './App.css';
import Navbar from "./navbar";
import Home from "./components/Home";
import About from "./components/About";
import Service from './components/Service';
import Contact from "./components/Contact";
// import Footer from "./components/Footer";

function App() {
  return (
    <>
    <div className="sticky-top">
    <Navbar/>
  </div>
      <div className="main">
        <Home/>
        <About/>
        <Service/>
        <Contact/>
        {/* <Footer/> */}
      
      </div>
</>
  );
}

export default App;
